import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css'; 
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="container --navbar">
        <div className="logo" > 
             <NavLink to="/" >
                Soldamos.pe
             </NavLink>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <i className={isOpen ? 'fa-solid fa-circle-xmark' : 'fa-solid fa-bars-staggered'}></i>
        </div>
        <div className={`nav-elements ${isOpen ? 'active' : ''}`}>
          <ul>
            <li className="item-menu">
              <NavLink to="/SpeedglasCascos" onClick={toggleMenu}>
                Speedglas G5-01
              </NavLink>
            </li>
            <li className="item-menu">
              <NavLink to="/Repuestos" onClick={toggleMenu}>
                Repuestos
              </NavLink>
            </li>
            <li className="item-menu">
                <NavLink to="/cotizar" onClick={toggleMenu}>
                    Solicitar Cotización
                </NavLink>
            </li>
            
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
