import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import productosData from './products.json';
import '../../assets/css/detailsproduct-base.css'; 
import '../../assets/css/detailsproduct-bootstrap.css'; 
import '../../assets/css/detailsproduct-fontawesome.css'; 
import '../../assets/css/detailsproduct-jqueryui.css'; 
import '../../assets/css/detailsproduct-slickapp.css'; 
import '../../assets/css/detailsproduct-slick.css'; 
import '../../assets/css/detailsproduct-style.css';
import './DetailsProduct.css'

const DetailsProduct = () => {

  const { productId } = useParams(); 
  const [producto, setProducto] = useState(null);
  const [mensajeAgregado, setMensajeAgregado] = useState('');
  const [mensajeExistente, setMensajeExistente] = useState('');
  const [mostrarMensajeAgregado, setMostrarMensajeAgregado] = useState(false);
  const [mostrarMensajeExistente, setMostrarMensajeExistente] = useState(false);

  useEffect(() => {
    const productoEncontrado = productosData.find((prod) => prod.headline === productId);
    setProducto(productoEncontrado);
  }, [productId]);

  const calcularAhorro = (precio, preciorebaja) => {
    const ahorro = precio - preciorebaja;
    return `Ahorra S/. ${ahorro.toFixed(2)}`;
  };

  const mostrarMensaje = (mensaje, setMensaje, setMostrarMensaje) => {
    setMensaje(mensaje);
    setMostrarMensaje(true);
    setTimeout(() => {
        setMostrarMensaje(false);
        setMensaje('');
    }, 2000); 
  };

  const agregarACotizacion = (id) => {
    let productosCotizados = JSON.parse(localStorage.getItem('productosCotizacion')) || [];
    const productoExistente = productosCotizados.find((producto) => producto.id === id);

    if (!productoExistente) {
        const productoSeleccionado = productosData.find((producto) => producto.id === id);
        productosCotizados.push(productoSeleccionado);
        localStorage.setItem('productosCotizacion', JSON.stringify(productosCotizados));
        mostrarMensaje('Producto añadido a cotización', setMensajeAgregado, setMostrarMensajeAgregado);
        setMensajeExistente('');
    } else {
        mostrarMensaje('Este producto ya está añadido', setMensajeExistente, setMostrarMensajeExistente);
        setMensajeAgregado('');
    }
};
  

  return (
    
      
<div className="sticky-header">

    <main className="main-wrapper">
    {producto ? (
        <div className="axil-single-product-area axil-section-gap pb--0 bg-color-white">
            <div className="single-product-thumb mb--40">
          
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 mb--40">
                            <div className="row">
                                <div className="col-lg-10 order-lg-2">
                                    <div className="single-product-thumbnail-wrap zoom-gallery">
                                        <div className="single-product-thumbnail product-large-thumbnail-3 axil-product">
                                            <div className="thumbnail">
                                                  {producto.imagen && <img src={producto.imagen} alt={producto.nombre} width="50%" />}
                                            </div>
                                        </div>
                                        <div className="product-quick-view position-view">
                                                <i className="fa-solid fa-expand"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 order-lg-1">
                                    <div className="product-small-thumb-3 small-thumb-wrapper">
                                        <div className="small-thumb-img">
                                              {producto.imagen && <img src={producto.imagen} alt={producto.nombre} width="50%" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mb--40">
                            <div className="single-product-content">
                                <div className="inner">
                                    <h2 className="product-title">{producto.nombre}</h2>

                                    {/*
                                       <span className="price-amount">S/.{producto.preciorebaja} - <span className="pricediscount">S/.{producto.precio}</span></span>
                                    */}

                                    <div className="product-rating">
                                        <div className="star-rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </div>
                                        <div className="review-link">
                                          {/*  (<span>2</span>  ) */}
                                        </div>
                                    </div>
                                    <ul className="product-meta">
                                        <li><i className="fa-solid fa-cubes"></i>En stock</li>
                                        <li><i className="fa-solid fa-file-circle-check"></i>Garantía de calidad</li>
                                        <li><i className="fa-solid fa-comment-dots"></i>Asería con Expertos</li>
                                    </ul>
                                    <p className="description">
                                      {producto.descripcion}
                                    </p>

                                    <div className="product-action-wrapper d-flex-center">
                                        <div className="pro-qty"><input type="text" value="1" /></div>
                                        <ul className="product-action d-flex-center mb--0">
                                            <li className="add-to-cart">
                                              <button onClick={() => agregarACotizacion(producto.id)} className="axil-btn btn-bg-primary">
                                                 Agregar a Cotización
                                              </button>
                                            </li>
                                            <li className="wishlist"><button className="axil-btn wishlist-btn"><i className="far fa-heart"></i></button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

             <div className="woocommerce-tabs wc-tabs-wrapper bg-vista-white">
                <div className="container">
                    <ul className="nav tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">Descripción del Producto</a>
                        </li>
                        {producto.caracteristicas && producto.caracteristicas.length > 0 && ( 
                        <li className="nav-item " role="presentation">
                            <a id="additional-info-tab" data-bs-toggle="tab" href="#additional-info" role="tab" aria-controls="additional-info" aria-selected="false">Información Adicional</a>
                        </li>
                        )}
                        <li className="nav-item --review" role="presentation">
                           
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                            <div className="product-desc-wrapper">
                                <div className="row">
                                    <div className="col-lg-6 mb--30">
                                        <div className="single-desc">
                                            <h5 className="title">{producto.titulocaracteristica}</h5>
                                            <p>{producto.descripcion}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb--30">
                                        <div className="single-desc --img-section">
                                            {producto.imagen && <img src={producto.imagen} alt={producto.nombre}  className="img-expand" />}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <ul className="pro-des-features">
                                            <li className="single-features">
                                                <div className="icon">
                                                    
                                                <svg className="svgiconproduct" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M11 5H19L17.1547 2.42858C16.9634 2.16232 16.6093 1.99879 16.2267 2.00001H11V5ZM9 2.00001H3.77333C3.39074 1.99879 3.03663 2.16232 2.84533 2.42858L1 5H9V2.00001ZM1 7V17.5C1 18.3284 1.67156 19 2.49997 19L8.5 19C9.5 19 10 18 10 18C10.0005 17.4658 10.0032 16.9327 10.0097 16.5C10.0097 12.9159 12.9234 10 16.5048 10H18C18 10 19 10 19 9V7H1ZM20 13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V14C18 15.1046 17.1046 16 16 16H15.4142L15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929C15.3166 13.9024 14.6834 13.9024 14.2929 14.2929L12.2929 16.2929C11.9024 16.6834 11.9024 17.3166 12.2929 17.7071L14.2929 19.7071C14.6834 20.0976 15.3166 20.0976 15.7071 19.7071C16.0976 19.3166 16.0976 18.6834 15.7071 18.2929L15.4142 18H16C18.2091 18 20 16.2091 20 14V13Z" fill="#000000"></path></g></svg> 
                                                </div>
                                                Devoluciones fáciles
                                            </li>
                                            <li className="single-features">
                                                <div className="icon">
                                                  <svg className="svgiconproduct" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="quality-5" transform="translate(-2 -2)"> <circle id="secondary" fill="#ffffff" cx="7" cy="7" r="7" transform="translate(5 3)"></circle> <path id="primary" d="M10,10l1.33,1.5L14,8.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path> <path id="primary-2" data-name="primary" d="M5.79,13.57,3,17.56l3.03.9L7.91,21l2.88-4.11" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path> <path id="primary-3" data-name="primary" d="M18.21,13.57,21,17.56l-3.03.9L16.09,21l-2.88-4.11" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path> <circle id="primary-4" data-name="primary" cx="7" cy="7" r="7" transform="translate(5 3)" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></circle> </g> </g></svg>
                                                </div>
                                                Servicio de calidad
                                            </li>
                                            <li className="single-features">
                                                <div className="icon">
                                                  <svg className="svgiconproduct" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="verified" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M21.6,9.84A4.57,4.57,0,0,1,21.18,9,4,4,0,0,1,21,8.07a4.21,4.21,0,0,0-.64-2.16,4.25,4.25,0,0,0-1.87-1.28,4.77,4.77,0,0,1-.85-.43A5.11,5.11,0,0,1,17,3.54a4.2,4.2,0,0,0-1.8-1.4A4.22,4.22,0,0,0,13,2.21a4.24,4.24,0,0,1-1.94,0,4.22,4.22,0,0,0-2.24-.07A4.2,4.2,0,0,0,7,3.54a5.11,5.11,0,0,1-.66.66,4.77,4.77,0,0,1-.85.43A4.25,4.25,0,0,0,3.61,5.91,4.21,4.21,0,0,0,3,8.07,4,4,0,0,1,2.82,9a4.57,4.57,0,0,1-.42.82A4.3,4.3,0,0,0,1.63,12a4.3,4.3,0,0,0,.77,2.16,4,4,0,0,1,.42.82,4.11,4.11,0,0,1,.15.95,4.19,4.19,0,0,0,.64,2.16,4.25,4.25,0,0,0,1.87,1.28,4.77,4.77,0,0,1,.85.43,5.11,5.11,0,0,1,.66.66,4.12,4.12,0,0,0,1.8,1.4,3,3,0,0,0,.87.13A6.66,6.66,0,0,0,11,21.81a4,4,0,0,1,1.94,0,4.33,4.33,0,0,0,2.24.06,4.12,4.12,0,0,0,1.8-1.4,5.11,5.11,0,0,1,.66-.66,4.77,4.77,0,0,1,.85-.43,4.25,4.25,0,0,0,1.87-1.28A4.19,4.19,0,0,0,21,15.94a4.11,4.11,0,0,1,.15-.95,4.57,4.57,0,0,1,.42-.82A4.3,4.3,0,0,0,22.37,12,4.3,4.3,0,0,0,21.6,9.84Zm-4.89.87-5,5a1,1,0,0,1-1.42,0l-3-3a1,1,0,1,1,1.42-1.42L11,13.59l4.29-4.3a1,1,0,0,1,1.42,1.42Z" ></path></g></svg>
                                                </div>
                                                Producto Original
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="additional-info" role="tabpanel" aria-labelledby="additional-info-tab">
                            <div className="product-additional-info">
                                <div className="table-responsive">
                                    <table>
                                        <tbody>
                                            <tr>
                                                {producto.caracteristicas}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        ) : (
          <p className="error-product"></p>
        )}
       
       {/*
        <div className="axil-product-area bg-color-white axil-section-gap pb--50 pb_sm--30">
            <div className="container">
                <div className="section-title-wrapper">
                    <span className="title-highlighter highlighter-primary"><i className="far fa-shopping-basket"></i>Productos Adicionales</span>
                    <h2 className="title">También te puede interesar</h2>
                </div>
            </div>
        </div>
      */}
    </main>


          {mostrarMensajeAgregado && (
                <div className="alert-success fade-out">{mensajeAgregado}</div>
            )}
            {mostrarMensajeExistente && (
                <div className="alert-error fade-out">{mensajeExistente}</div>
            )}
</div>

  );
};

export default DetailsProduct;