import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import productosData from './products.json';
import './ListProducts.css'; 


const ListProducts = () => {
    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const [mensajeAgregado, setMensajeAgregado] = useState('');
    const [mensajeExistente, setMensajeExistente] = useState('');
    const [mostrarMensajeAgregado, setMostrarMensajeAgregado] = useState(false);
    const [mostrarMensajeExistente, setMostrarMensajeExistente] = useState(false);
  
    useEffect(() => {
        const productosRepuestos = productosData.filter(producto => producto.categoria.includes('Repuestos'));
        setProductosFiltrados(productosRepuestos);
    }, []);
   
    const mostrarMensaje = (mensaje, setMensaje, setMostrarMensaje) => {
        setMensaje(mensaje);
        setMostrarMensaje(true);
        setTimeout(() => {
            setMostrarMensaje(false);
            setMensaje('');
        }, 2000); 
    };

    const calcularAhorro = (precio, preciorebaja) => {
      const ahorro = precio - preciorebaja;
      return `Ahorra S/. ${ahorro.toFixed(2)}`;
    };
  
    const agregarACotizacion = (id) => {
        let productosCotizados = JSON.parse(localStorage.getItem('productosCotizacion')) || [];
        const productoExistente = productosCotizados.find((producto) => producto.id === id);

        if (!productoExistente) {
            const productoSeleccionado = productosData.find((producto) => producto.id === id);
            productosCotizados.push(productoSeleccionado);
            localStorage.setItem('productosCotizacion', JSON.stringify(productosCotizados));
            mostrarMensaje('Producto añadido a cotización', setMensajeAgregado, setMostrarMensajeAgregado);
            setMensajeExistente('');
        } else {
            mostrarMensaje('Este producto ya está añadido', setMensajeExistente, setMostrarMensajeExistente);
            setMensajeAgregado('');
        }
    };

  return (

   <div className="grid-list-items --section-list-products">
         <div className="data-container-products">
            <div className="products-container">
                    {productosFiltrados.map((producto) => (
                    <div className="product-card" key={producto.id}>
                        <div className="discount-tag">
                            {/*
                            {producto.preciorebaja && producto.precio &&
                            calcularAhorro(producto.precio, producto.preciorebaja)
                            } 
                            */}
                            Tendencia
                        </div>

                        {producto.imagen && (
                            <Link to={`/detalle/${producto.headline}`}>
                                <img src={producto.imagen} alt={producto.nombre} className="product-image" width="35%" />
                            </Link>
                        )}
                            
                        <div className="product-details">
                            <h1 className="product-name">{producto.nombre}</h1>
                            <p className="product-sku">Número de Serie: <span className="xl">{producto.sku}</span></p>
                            <p className="product-brand">{producto.marca}</p>

                            {/*
                                        <p className="product-price">S/.{producto.preciorebaja}</p>
                                        <p className="product-discounted-price">S/.{producto.precio}</p>

                            */}
                        
                            <button className="quote-button" onClick={() => agregarACotizacion(producto.id)}>
                                Solicitar Cotización
                            </button>
                        </div>
                    </div>
                    ))}
            </div>
         </div>
            {mostrarMensajeAgregado && (
                <div className="alert-success fade-out">{mensajeAgregado}</div>
            )}
            {mostrarMensajeExistente && (
                <div className="alert-error fade-out">{mensajeExistente}</div>
            )}
   </div>

  );
};

export default ListProducts;