import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar'
import NavbarMobile from './components/Navbar/NavbarMobile'
import Home from './components/Pages/Home'
import Cotizacion from './components/Pages/Cotizar'
import SpeedglasCascos from './components/Sections/SpeedglasCascos'
import Repuestos from './components/Sections/SpeedglasRepuestos'
import DetalleProducto from './components/Products/DetailsProduct';
import Footer from './components/Footer/Footer';

const App = () => {
  return (
    <Router>
    <Navbar />
    <NavbarMobile />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/cotizar" element={<Cotizacion />} />
        <Route path="/speedglascascos" element={<SpeedglasCascos />} />
        <Route path="/repuestos" element={<Repuestos />} />
        <Route path="/detalle/:productId" element={<DetalleProducto />} /> 
      </Routes>
    <Footer />
    </Router>
  );
};

export default App;