import React from 'react';
import './ProductsSection.css'; 

const   ProductsSection = () => {
  return (
    <div className="container-views --products">
     <div className="row-products-section">
            <div className="title --c">
            <h1 className="title-section-products">Productos Populares</h1>
            </div>
            <div className="divider --c">
                <span className="line-divider"></span>
            </div>
     </div>
    </div>
  );
};

export default ProductsSection;
