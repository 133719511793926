import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import './Cotizar.css'


const Cotizar = () => {
  const [productosCotizados, setProductosCotizados] = useState([]);
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    unidades: '',
  });

  useEffect(() => {
    const productosCotizacion = JSON.parse(localStorage.getItem('productosCotizacion')) || [];
    setProductosCotizados(productosCotizacion);
  }, []);

  const eliminarProducto = (id) => {
    const productosActualizados = productosCotizados.filter((producto) => producto.id !== id);
    setProductosCotizados(productosActualizados);
    localStorage.setItem('productosCotizacion', JSON.stringify(productosActualizados));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
       console.log('Formulario enviado:', formData, productosCotizados);
       alert()
    localStorage.removeItem('productosCotizacion');
    setProductosCotizados([]);
    setFormData({ nombre: '', correo: '', unidades: '' });
  };

return(
   
<div class="section-primary --quote">

   <div className="section --quote">
       <div className="container --elements-quote">
         <div className="row --q">
            <div className="quote-column --v1">
                  <div className="information">
                        <div className="data --requiredinfo">
                              <div className="quote --title">
                                 <h1 className="title-seopage">¿Buscas una cotización para algún producto 3M Speedglas?</h1>
                              </div>
                              <div className="quote --subtitle">
                                 <p className="subtitle-page">Entendemos que comprar cascos para soldar en línea con tarjeta de crédito no es adecuado para muchos, por lo que nos complace trabajar en los procesos de adquisición para garantizar que podamos ayudar a mantener protegidos a todos los soldadores.</p>
                              </div>
                        </div>
                  </div>
            </div>
            <div className="quote-column --v2">
                  <div className="data --requiredform">
                           <div className="container-formquotes">
                               <div className="validation-quotes">
                                 <h1 className="page-quotetitle">Solicita una cotización GRATIS hoy!</h1>
                                 {productosCotizados.length > 0 ? (
                                    <div className="container-products --quote-q">
                                       <h2 className="react-quote">Productos en Cotización:</h2>
                                       
                                       <div className="container-main-q">
                                       {productosCotizados.map((producto) => (
                                       <div className="react-container-quote" key={producto.id}>
                                          <div className="row-react-quote">
                                             <div className="product-with-quotes">
                                                <h3 className="product-q">Producto: <span className="name-product-q"> {producto.nombre} </span></h3>
                                                {/*  <p className="product-price">Precio: <span className="price-product-q">S/.{producto.preciorebaja}</span></p>  */}
                                             </div>
                                             <button className="remove-product-quote" onClick={() => eliminarProducto(producto.id)}>
                                                <i className="fas fa-times"></i>
                                             </button>
                                          </div>
                                       </div>
                                       ))}
                                       </div>

                                    </div>
                                 ) : (
                                    <p className="error-msj-script">No se ha seleccionado ningún producto para cotizar.</p>
                                 )}
                               </div>

                             <form onSubmit={handleSubmit}>
                                 <h1 className="data-info-user">Envíe sus datos a continuación y le enviaremos una cotización dentro de las 24 horas.</h1>
                                 <label htmlFor="nombre" className="title-labelformquote">Nombre:</label>
                                 <input
                                    type="text"
                                    id="nombre"
                                    name="nombre"
                                    value={formData.nombre}
                                    onChange={handleInputChange}
                                    required
                                 />

                                 <label htmlFor="correo" className="title-labelformquote">Correo Electrónico:</label>
                                 <input
                                    type="email"
                                    id="correo"
                                    name="correo"
                                    value={formData.correo}
                                    onChange={handleInputChange}
                                    required
                                 />

                                 <label htmlFor="unidades" className="title-labelformquote">Unidades:</label>
                                 <input
                                    type="number"
                                    id="unidades"
                                    name="unidades"
                                    value={formData.unidades}
                                    onChange={handleInputChange}
                                    required
                                 />
                                
                                 <label className="title-labelformquote">Productos Solicitados:</label>
     
                                 <textarea
                                    className="title-labelformquote"
                                    value={productosCotizados
                                       .map((producto) => `${producto.nombre} | `)
                                       .join('')}
                                    rows={3}
                                    readOnly
                                 />

                                 <button type="submit" className="btn-send-quote">Enviar Cotización</button>
                              </form>

                           </div>
                                          
                  </div>
            </div>
         </div>
       </div>  
   </div>




</div>
   

);
};

export default Cotizar;