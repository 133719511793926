import React from 'react';
import { NavLink } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import './NavbarMobile.css';

const NavbarMobile = () => {

  return (
    <nav className="navbar-mobile">
      <ul className="nav-links">
        <li className="nav-item">
          <NavLink to="/" exact>
            <i className="fas fa-home"></i> Inicio
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/cotizar">
            {/* <i className="fas fa-file-invoice-dollar"></i> Cotización
            {contadorCotizacion > 0 && (
              <span className="badge">{contadorCotizacion}</span>
             )} */}
             <i className="fas fa-file-invoice-dollar"></i> Cotización
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/SpeedglasCascos">
            <i className="fas fa-box-open"></i> Productos
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/Repuestos">
            <i className="fas fa-th"></i> Repuestos
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavbarMobile;
