import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (
    <footer>
      
      <div className="container-footer">
          <div className="footer-column">
            <NavLink to="/" className="footer-a" >
                    Soldamos.pe
            </NavLink>
          </div>

          <div className="footer-column">
            <ul className="listamenu">
              <li>
                  <NavLink to="/SpeedglasCascos" onClick={toggleMenu}>
                    Speedglas G5-01
                  </NavLink>
              </li>
              <li>
                  <NavLink to="/Repuestos" onClick={toggleMenu}>
                  Repuestos
                  </NavLink>
              </li>
             
            </ul>
          </div>

          <div className="footer-column">
            {/* 
            
            <address>
              <p>Correo: cotizacion@soldamos.pe</p>
            </address>
            
            */}
          </div>
      </div>
      <div className="footer-copyright">
        <p>&copy; 2024 Soldamos.pe . Todos los derechos reservados.</p>
      </div>
    </footer>
    
  );
};

export default Footer;
