import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import productosData from '../Products/products.json';
import '../../assets/css/detailsproduct-base.css'; 
import '../../assets/css/detailsproduct-bootstrap.css'; 
import '../../assets/css/detailsproduct-fontawesome.css'; 
import '../../assets/css/detailsproduct-jqueryui.css'; 
import '../../assets/css/detailsproduct-slickapp.css'; 
import '../../assets/css/detailsproduct-slick.css'; 
import '../../assets/css/detailsproduct-style.css';
import './SpeedglasRepuestos.css'


const SpeedglasRepuestos = () => {

    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const [mensajeAgregado, setMensajeAgregado] = useState('');
    const [mensajeExistente, setMensajeExistente] = useState('');
    const [mostrarMensajeAgregado, setMostrarMensajeAgregado] = useState(false);
    const [mostrarMensajeExistente, setMostrarMensajeExistente] = useState(false);
  
    useEffect(() => {
        const productosRepuestos = productosData.filter(producto => producto.categoria.includes('Repuestos'));
        setProductosFiltrados(productosRepuestos);
    }, []);
   
    const mostrarMensaje = (mensaje, setMensaje, setMostrarMensaje) => {
        setMensaje(mensaje);
        setMostrarMensaje(true);
        setTimeout(() => {
            setMostrarMensaje(false);
            setMensaje('');
        }, 2000); 
    };

    const calcularAhorro = (precio, preciorebaja) => {
      const ahorro = precio - preciorebaja;
      return `Ahorra S/. ${ahorro.toFixed(2)}`;
    };
  
    const agregarACotizacion = (id) => {
        let productosCotizados = JSON.parse(localStorage.getItem('productosCotizacion')) || [];
        const productoExistente = productosCotizados.find((producto) => producto.id === id);

        if (!productoExistente) {
            const productoSeleccionado = productosData.find((producto) => producto.id === id);
            productosCotizados.push(productoSeleccionado);
            localStorage.setItem('productosCotizacion', JSON.stringify(productosCotizados));
            mostrarMensaje('Producto añadido a cotización', setMensajeAgregado, setMostrarMensajeAgregado);
            setMensajeExistente('');
        } else {
            mostrarMensaje('Este producto ya está añadido', setMensajeExistente, setMostrarMensajeExistente);
            setMensajeAgregado('');
        }
    };

    return (
      
        <div className="sticky-header">
        <main className="main-wrapper">        
            <div className="axil-breadcrumb-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col">
                            <div className="inner">
                                <ul className="axil-breadcrumb">
                                    <li className="axil-breadcrumb-item"><a>Inicio</a></li>
                                    <li className="separator"></li>
                                    <li className="axil-breadcrumb-item active" aria-current="page">Repuestos</li>
                                </ul>
                                <h1 className="title">Explorar todos los productos</h1>
                                <p>
                                Todas las piezas y repuestos Speedglas están respaldados por nuestra promesa de mejor precio. Si compra con Welding Helmets Online sabe que está comprando los repuestos adecuados a nuestros mejores precios. Finalmente, una tienda en línea que se centra puramente en los cascos de soldadura y entiende Speedglas piezas de repuesto.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
            <div className="axil-shop-area axil-section-gap bg-color-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="axil-shop-sidebar">
                                <div className="d-lg-none">
                                    <button className="sidebar-close filter-close-btn"><i className="fas fa-times"></i></button>
                                </div>
                                <div className="toggle-list product-categories active">
                                    <h6 className="title">Por Necesidad</h6>
                                    <div className="shop-submenu">
                                        <ul>
                                            <li className="current-cat"><a  >Recambios 3M Speedglas Adflo PAPR</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="toggle-list product-size active">
                                    <h6 className="title">Etiquetas</h6>
                                    <div className="shop-submenu">
                                        <ul>
                                            <li><a  >Placa de esmerilado transparente</a></li>
                                            <li><a  >Piezas para cascos de soldadura</a></li>
                                            <li><a  >Lente de aumento</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="axil-shop-top mb--40">
                                        <div className="category-select align-items-center justify-content-lg-end justify-content-between">
                                           
                                            <span className="filter-results">Mostrando 1-2 resultados</span>
                                            <select className="single-select">
                                                <option>Ordernar por nombre</option>
                                                <option>Ordernar por precio</option>
                                            </select>
                                        
                                        </div>
                                        <div className="d-lg-none">
                                            <button className="product-filter-mobile filter-toggle"><i className="fas fa-filter"></i> FILTROS</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         
                            <div className="row row--15">

                            {productosFiltrados.map((producto) => (
                                <div className="col-xl-4 col-sm-6"  key={producto.id}>
                                    <div className="axil-product product-style-one mb--30">
                                        <div className="thumbnail">
                                            <a>
                                            {producto.imagen && (
                                                <Link to={`/detalle/${producto.headline}`}>
                                                    <img src={producto.imagen} alt={producto.nombre} className="product-image" width="35%" />
                                                </Link>
                                            )}
                                            </a>
                                            <div className="label-block label-right">
                                                <div className="product-badget">
                                              {/*
                                                        {producto.preciorebaja && producto.precio &&
                                                        calcularAhorro(producto.precio, producto.preciorebaja)
                                                        } 
                                              */}
                                              Repuestos
                                                </div>
                                            </div>
                                            <div className="product-hover-action">
                                                <ul className="cart-action">
                                                    <li className="wishlist"><a><i className="far fa-heart"></i></a></li>
                                                    <li className="select-option">
                                                        <button className="btnacti"  onClick={() => agregarACotizacion(producto.id)}>
                                                            Solicitar Cotización
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-content">
                                            <div className="inner">
                                                <h5 className="title">
                                                    <Link to={`/detalle/${producto.headline}`}>{producto.nombre}</Link>
                                                </h5>
                                                <div className="product-price-variant">
                                                    {/*     <span className="price current-price">S/.{producto.preciorebaja}</span>
                                                    <span className="price old-price">S/.{producto.precio}</span>
                                                    */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               ))}
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
                                {mostrarMensajeAgregado && (
                                    <div className="alert-success fade-out">{mensajeAgregado}</div>
                                )}
                                {mostrarMensajeExistente && (
                                    <div className="alert-error fade-out">{mensajeExistente}</div>
                                )}
        </main>
    
      
    
    </div>
    );
  };


export default SpeedglasRepuestos;